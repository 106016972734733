import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { mdi } from "vuetify/iconsets/mdi";
import { ms } from "@/vuetify/iconsets/material_symbols";

export const vuetifySettings = {
	components,
	directives,
	icons: {
		defaultSet: "mdi",
		sets: {
			mdi,
			ms,
		},
	},
	theme: {
		defaultTheme: "displaysTheme",
		themes: {
			displaysTheme: {
				dark: false,
				colors: {
					background: "#FFFFFF",
					surface: "#FFFFFF",
					transparent: "#FFFFFF",

					black: "#000000",
					white: "#FFFFFF",
					colorGrey1000: "#171717",
					colorGrey900: "#303030",
					colorGrey800: "#585959",
					colorGrey700: "#8c8d8d",
					colorGrey600: "#a6a6a6",
					colorGrey500: "#afafaf",
					colorGrey400: "#dbdbdb",
					colorGrey300: "#e0e0e0",
					colorGrey250: "#e9e6e4",
					colorGrey200: "#ededed",
					colorGrey100: "#f6f6f6",

					colorYellow500: "#EFEBB9",
					colorYellow800: "#ffc787",

					colorPink700: "#EDC6C6",

					colorGreen300: "#ADDB67",

					colorBlue500: "#A7D1DB",

					primary: "#171717",
					secondary: "#E9E6E4",
					tertiary: "#FFFFFF",

					error: "#F65F21",
					info: "#4d63e8",
					success: "#18AE08",
					warning: "#F57F17",

					progressColor: "#585959",
					progressBgColor: "#585959",
				},
				variables: {
					"input-padding-top": "12px",
					"field-padding-bottom": "8px !important",
				},
			},
		},
	},
	ssr: !!import.meta.env.SSR,
};

export default vuetifySettings;
