const env = {};

Object.keys(import.meta.env).forEach((key) => {
	let newKey = key;
	if (key.indexOf("VITE_") === 0) {
		newKey = key.replace("VITE_", "");
	}
	env[newKey] = import.meta.env[key];
});

export default env;
