import PermissionsMiddleware from "@@/middlewares/PermissionsMiddleware";

export const MiddlewareController = (context) => {
	if (!context) return;

	const middlewares = setupMiddlewares(context.to);

	if (!middlewares.length) return context.next;

	return async () => {
		return await pipeline(context, middlewares);
	};
};

const pipeline = async (context, middlewares, index = 0) => {
	const nextMiddleware = middlewares[index];

	if (!nextMiddleware) return await context.next();

	return await nextMiddleware({
		...context,
		next: async () => {
			return await pipeline(context, middlewares, index + 1);
		},
	});
};

const setupMiddlewares = (to) => {
	if (!to) return [];
	if (!to.matched || !to.matched.length) return [];

	return to.matched.reduce((result, to) => {
		if (!!to.meta.middleware && !!to.meta.middleware.length) {
			result = [...result, ...to.meta.middleware];
		}

		if (to.meta.permissions) {
			result.push(function _PermissionsMiddleware(context) {
				return PermissionsMiddleware({ ...context, permissions: to.meta.permissions });
			});
		}
		return result;
	}, []);
};

export default MiddlewareController;
