import { useCookies } from "@vueuse/integrations/useCookies";
import { useSanctumStore } from "@@/stores/useSanctumStore";

export const SanctumMiddleware = async ({ to, next }) => {
	const { get } = useCookies();
	const csrfToken = get("XSRF-TOKEN");
	if (csrfToken) {
		return await next();
	}

	const sanctumStore = useSanctumStore();

	await sanctumStore
		.csrf()
		.then(() => {})
		.catch(() => {});

	return await next();
};

export default SanctumMiddleware;
