import { h } from "vue";

const ms = {
	component: (props) => {
		let className = "";
		let style = {};
		switch (props.ms) {
			case "material-symbols-rounded":
			case "rounded":
				className = "material-symbols-rounded";
				break;
			case "material-symbols-sharp":
			case "sharp":
				className = "material-symbols-sharp";
				break;
			case "material-symbols-outlined":
			case "outlined":
			default:
				className = "material-symbols-outlined";
				style = { fontWeight: 300 };
				break;
		}
		return h(props.tag, { class: [props.class, className], style: style, innerHTML: props.icon });
	},
};

export { ms };
