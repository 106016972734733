import { useLocaleStore } from "@@/stores/useLocaleStore";
import useRouteDefault from "@/composables/useRouteDefault";
import useAuthStatus from "@/composables/auth/useAuthStatus.js";

export const AuthFailedMiddleware = async ({ to, next }) => {
	const { check } = useAuthStatus();

	return await check()
		.then((response) => {
			const locale = to.params.locale ? to.params.locale : useLocaleStore().getLocale;
			return { name: useRouteDefault().defaultRoute(), params: { locale: locale } };
		})
		.catch(async (response) => {
			return await next();
		});
};

export default AuthFailedMiddleware;
