const filesCore = import.meta.glob("./*.js", { eager: true });
const filesApp = import.meta.glob("./../../config/*.js", { eager: true });
const files = { ...filesCore, ...filesApp };
const config = {};

for (const path in files) {
	const pathSplit = path.split("/");

	const moduleName = pathSplit[pathSplit.length - 1].split(".")[0];

	config[moduleName] = files[path][moduleName];
}

export default config;
