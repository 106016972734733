export const RouteEnum = {
	// BASE
	ERROR_403: "error.403",
	ERROR_404: "error.404",

	// AUTH
	LOCALE_CHOOSE: "localeChoose",
	LOGIN: "auth.login",
	LOGOUT: "auth.logout",
	PASSWORD_FORGOT: "auth.password.forgot",
	PASSWORD_RESET: "auth.password.reset",

	//APP
	CART: "orders.cart",
	CHECKOUT: "orders.checkout",
};
