import { computed, reactive } from "vue";
import { AlertTypeEnum } from "@@/enums/AlertTypeEnum.js";
import useId from "@@/composables/useId.js";
import useConfig from "@@/composables/useConfig.js";

const alertState = reactive({
	alerts: [],
});

export default () => {
	const { idS4 } = useId();
	const alertConfig = useConfig().alert;

	const alerts = computed(() => {
		return alertState.alerts;
	});

	const pushAlert = (alert = null, type = null) => {
		if (!alert) return null;

		const note = {
			id: `alert-id--${idS4()}`,
			title: "",
			content: "",
			type: type ? type : AlertTypeEnum.INFO,
			timeout: alertConfig.timeout,
			props: {},
		};

		if (typeof alert === "string")
			alert = {
				content: alert,
			};

		const alertObject = {
			...note,
			...alert,
		};

		alertState.alerts.push(alertObject);

		return alertObject;
	};

	const removeAlert = (alertToRemove) => {
		if (!alertToRemove) return false;
		if (typeof alertToRemove !== "object") return false;
		if (!("id" in alertToRemove)) return false;

		alertState.alerts = alertState.alerts.filter((alert) => alert.id !== alertToRemove.id);

		return true;
	};

	return {
		alertState,
		alerts,
		pushAlert,
		removeAlert,
	};
};
