import { computed, reactive } from "vue";
import { AsideKindEnum } from "@@/enums/AsideKindEnum";

const asideState = reactive({
	open: false,
	fullScreen: false,
	kind: AsideKindEnum.DEFAULT,
	parent: null,
	drawerAttrs: null,
});

export default () => {
	const isOpenAside = computed({
		get() {
			return asideState.open;
		},
		set(value) {
			asideState.open = !!value;
		},
	});

	const setDefaultStateData = () => {
		asideState.kind = AsideKindEnum.DEFAULT;
		asideState.drawerAttrs = {
			width: 660,
		};
	};

	return {
		asideState,
		isOpenAside,
		setDefaultStateData,
	};
};
