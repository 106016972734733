import AuthPassedMiddleware from "@@/middlewares/AuthPassedMiddleware";
import AuthFailedMiddleware from "@@/middlewares/AuthFailedMiddleware";

const authRoutes = [
	{
		name: "auth.passed",
		path: "",
		component: () => import("@/views/Blank.vue"),
		meta: {
			middleware: [AuthPassedMiddleware],
		},
		children: [
			{
				name: "auth.passed.index",
				path: "",
				redirect: {
					name: "main",
					params: {
						locale: "pl",
					},
				},
			},
			{
				name: "auth.logout",
				path: "logout",
				component: () => import("@/views/pages/auth/AuthLogout.vue"),
			},
		],
	},
	{
		name: "auth.failed",
		path: "",
		component: () => import("@/views/Blank.vue"),
		meta: {
			middleware: [AuthFailedMiddleware],
		},
		children: [
			{
				name: "auth.login",
				path: "login",
				component: () => import("@/views/pages/auth/AuthLogin.vue"),
			},
			{
				name: "auth.verification",
				path: "verification/:token",
				component: () => import("@/views/pages/auth/AuthVerification.vue"),
			},
			{
				name: "auth.verification.step",
				path: "verification-two-step/:token",
				component: () => import("@/views/pages/auth/AuthVerificationTwoStep.vue"),
			},
			{
				name: "auth.password.forgot",
				path: "password-forgot",
				component: () => import("@/views/pages/auth/AuthPasswordForgot.vue"),
			},
			{
				name: "auth.password.reset",
				path: "password-reset/:token",
				component: () => import("@/views/pages/auth/AuthPasswordReset.vue"),
			},
		],
	},
];

export default authRoutes;
