import { computed } from "vue";
import useAuthActions from "@/composables/auth/useAuthActions.js";

export default () => {
	const { authStore } = useAuthActions();

	const permissions = computed(() => {
		return authStore.getUserPermissions;
	});

	const hasPermissions = (permissionsToCheck) => {
		if (!permissionsToCheck) return true;
		if (!permissions.value) return false;
		if (!Array.isArray(permissions.value)) return false;
		if (!permissions.value.length) return false;

		if (typeof permissionsToCheck === "string") permissionsToCheck = [permissionsToCheck];

		return permissionsToCheck.reduce((result, permission) => {
			result &= permissions.value.includes(permission);
			return result;
		}, true);
	};

	const hasAnyPermissions = (permissionsToCheck) => {
		if (!permissionsToCheck) return true;
		if (!permissions.value) return false;
		if (typeof permissionsToCheck === "string") permissionsToCheck = [permissionsToCheck];

		return permissionsToCheck.reduce((result, permission) => {
			result |= permissions.value.includes(permission);
			return result;
		}, false);
	};

	const hasNotPermissions = (permissionsToCheck) => {
		return !hasPermissions(permissionsToCheck);
	};

	return {
		hasPermissions,
		hasAnyPermissions,
		hasNotPermissions,
	};
};
