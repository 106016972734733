import { computed } from "vue";
import useAuthActions from "@/composables/auth/useAuthActions.js";

export default () => {
	const { authStore } = useAuthActions();

	const user = computed(() => {
		return authStore.getUser;
	});

	const userRoleId = computed(() => {
		return authStore.getUserRoleId;
	});

	const userCurrency = computed(() => {
		return authStore.getUserCurrency;
	});

	return {
		user,
		userRoleId,
		userCurrency,
	};
};
