import { setLocale } from "yup";
import routesAll from "@/routes/general";
import messages from "@@/lang/index.js";
import { useLoadingStore } from "@@/stores/useLoadingStore";
import { useLocaleStore } from "@@/stores/useLocaleStore";
import { useCurrencyStore } from "@/stores/useCurrencyStore.js";
import MiddlewareController from "@@/middlewares/MiddlewareController.js";
import useAsideParent from "@@/composables/aside/useAsideParent";

export const routes = routesAll;

export const beforeEach = async (to, from) => {
	const loadingStore = useLoadingStore($pinia);
	loadingStore.routeStart(to.name);

	const middlewareNext = MiddlewareController({
		to,
		from,
		next: async function _beforeEachNext() {
			return true;
		},
	});
	if (!!middlewareNext) return await middlewareNext();

	return true;
};

export const afterEach = async (to, from) => {
	const localeStore = useLocaleStore();

	localeStore.setLocale(to.params.locale);
	$i18n.global.locale.value = localeStore.getLocale;

	if (
		messages[localeStore.getLocale] &&
		messages[localeStore.getLocale]["formValidate"] &&
		messages[localeStore.getLocale]["formValidate"]["yup"]
	) {
		setLocale(messages[localeStore.getLocale]["formValidate"]["yup"]);
	}

	const currencyStore = useCurrencyStore();
	currencyStore.init();

	const loadingStore = useLoadingStore($pinia);
	loadingStore.routeStop(to.name);

	const { setParent } = useAsideParent();
	setParent(from);
};

export default { routes, beforeEach, afterEach };
