export const locales = [
	{
		id: "pl",
		slug: "pl",
		default: true,
		name: {
			pl: "Polski",
			en: "Polish",
		},
		dateTimeFormat: {
			date: "yyyy-MM-dd",
			time: "HH:mm",
			dateTime: "yyyy-MM-dd HH:mm",
		},
	},
];
