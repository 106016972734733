import { PermissionEnum } from "@/enums/PermissionEnum.js";

const adminRoutes = [
	{
		name: "admin.users",
		path: "users",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "admin.users.index",
		},
		meta: {
			permissions: PermissionEnum.ADMIN__USERS__CRUD,
		},
		children: [
			{
				name: "admin.users.index",
				path: "",
				component: () => import("@/views/pages/users/UsersIndex.vue"),
				meta: {
					breadcrumbs: {
						hidden: true,
					},
				},
			},
			{
				name: "admin.users.admin.index",
				path: "admin",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnum.ADMIN__USERS__CRUD,
					breadcrumbs: {
						hidden: true,
					},
				},
				redirect: {
					name: "admin.users.admin.list",
				},
				children: [
					{
						name: "admin.users.admin.list",
						path: "list",
						component: () => import("@/views/pages/users/admin/UsersAdminListing.vue"),
						children: [
							{
								name: "admin.users.admin.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/admin/UsersAdminAdd.vue"),
								},
							},
							{
								name: "admin.users.admin.edit",
								path: "edit/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/admin/UsersAdminEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "admin.users.seller.index",
				path: "seller",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnum.ADMIN__USERS__CRUD,
					breadcrumbs: {
						hidden: true,
					},
				},
				redirect: {
					name: "admin.users.seller.list",
				},
				children: [
					{
						name: "admin.users.seller.list",
						path: "list",
						component: () => import("@/views/pages/users/seller/UsersSellerListing.vue"),
						children: [
							{
								name: "admin.users.seller.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/seller/UsersSellerAdd.vue"),
								},
							},
							{
								name: "admin.users.seller.edit",
								path: "edit/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/seller/UsersSellerEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "admin.users.producer.index",
				path: "producer",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnum.ADMIN__USERS__CRUD,
					breadcrumbs: {
						hidden: true,
					},
				},
				redirect: {
					name: "admin.users.producer.list",
				},
				children: [
					{
						name: "admin.users.producer.list",
						path: "list",
						component: () => import("@/views/pages/users/producer/UsersProducerListing.vue"),
						children: [
							{
								name: "admin.users.producer.add",
								path: "add",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/producer/UsersProducerAdd.vue"),
								},
							},
							{
								name: "admin.users.producer.edit",
								path: "edit/:id",
								component: () => import("@/views/Blank.vue"),
								meta: {
									aside: () => import("@/views/pages/users/producer/UsersProducerEdit.vue"),
								},
							},
						],
					},
				],
			},
			{
				name: "admin.users.userLogs",
				path: "user-logs",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: PermissionEnum.ADMIN__USER_LOGS__READ,
					breadcrumbs: {
						hidden: true,
					},
				},
				redirect: {
					name: "admin.users.userLogs.list",
				},
				children: [
					{
						name: "admin.users.userLogs.list",
						path: "list",
						component: () => import("@/views/pages/users/user_logs/UsersUserLogsListing.vue"),
					},
				],
			},
		],
	},
	{
		name: "admin.producers.index",
		path: "producers",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.ADMIN__PRODUCERS__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "admin.producers.list",
		},
		children: [
			{
				name: "admin.producers.list",
				path: "list",
				component: () => import("@/views/pages/producers/list/ProducersListing.vue"),
				children: [
					{
						name: "admin.producers.add",
						path: "add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/producers/ProducersAdd.vue"),
							permissions: [PermissionEnum.ADMIN__PRODUCERS__CRUD],
						},
					},
					{
						name: "admin.producers.edit",
						path: "edit/:id",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/producers/ProducersEdit.vue"),
							permissions: [PermissionEnum.ADMIN__PRODUCERS__CRUD],
						},
					},
				],
			},
		],
	},
	{
		name: "admin.collections.index",
		path: "collections",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.ADMIN__COLLECTIONS__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "admin.collections.list",
		},
		children: [
			{
				name: "admin.collections.list",
				path: "list",
				component: () => import("@/views/pages/collections/list/CollectionsListing.vue"),
				children: [
					{
						name: "admin.collections.add",
						path: "add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/collections/CollectionsAdd.vue"),
							permissions: [PermissionEnum.ADMIN__COLLECTIONS__CRUD],
						},
					},
					{
						name: "admin.collections.edit",
						path: "edit/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/collections/CollectionsEdit.vue"),
							permissions: [PermissionEnum.ADMIN__COLLECTIONS__CRUD],
						},
					},
				],
			},
		],
	},
	{
		name: "admin.brands.index",
		path: "brands",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.ADMIN__BRANDS__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "admin.brands.list",
		},
		children: [
			{
				name: "admin.brands.list",
				path: "list",
				component: () => import("@/views/pages/brands/list/BrandsListing.vue"),
				children: [
					{
						name: "admin.brands.add",
						path: "add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/brands/BrandsAdd.vue"),
							permissions: [PermissionEnum.ADMIN__BRANDS__CRUD],
						},
					},
					{
						name: "admin.brands.edit",
						path: "edit/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/brands/BrandsEdit.vue"),
							permissions: [PermissionEnum.ADMIN__BRANDS__CRUD],
						},
					},
				],
			},
		],
	},
	{
		name: "admin.products.index",
		path: "products",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.ADMIN__PRODUCTS__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "admin.products.list",
		},
		children: [
			{
				name: "admin.products.list",
				path: "list",
				component: () => import("@/views/pages/products/list/ProductsListing.vue"),
				children: [
					{
						name: "admin.products.add",
						path: "add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/products/ProductsAdd.vue"),
							permissions: [PermissionEnum.ADMIN__PRODUCTS__CRUD],
						},
					},
					{
						name: "admin.products.edit",
						path: "edit/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/products/ProductsEdit.vue"),
							permissions: [PermissionEnum.ADMIN__PRODUCTS__CRUD],
						},
					},
				],
			},
		],
	},
	{
		name: "admin.orders.index",
		path: "orders",
		component: () => import("@/views/Blank.vue"),
		meta: {
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "admin.orders.list",
		},
		children: [
			{
				name: "admin.orders.list",
				path: "list",
				component: () => import("@/views/pages/orders/list/OrdersListing.vue"),
				meta: {
					permissions: [PermissionEnum.ADMIN__ORDERS__READ],
				},
				children: [
					{
						name: "admin.orders.preview",
						path: "preview/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/orders/edit/OrdersPreview.vue"),
							permissions: [PermissionEnum.ADMIN__ORDERS__READ],
						},
					},
					{
						name: "admin.orders.edit",
						path: "edit/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/orders/edit/OrdersEdit.vue"),
							permissions: [PermissionEnum.ADMIN__ORDERS__UPDATE],
						},
					},
					{
						name: "admin.orders.statusLogs",
						path: "status-logs/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/orders/status_logs/OrdersStatusLogs.vue"),
							permissions: [PermissionEnum.ADMIN__ORDERS__READ],
						},
					},
				],
			},
		],
	},
	{
		name: "admin.settings.index",
		path: "settings",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.ADMIN__SETTINGS__CRUD],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "admin.settings.mail",
		},
		children: [
			{
				name: "admin.settings.mail",
				path: "mail",
				component: () => import("@/views/pages/settings/SettingsMail.vue"),
			},
		],
	},
];

export default adminRoutes;
