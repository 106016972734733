import axios from "axios";
import { useLoadingStore } from "@@/stores/useLoadingStore";
import useAlertAdd from "@@/composables/alert/useAlertAdd.js";
import useAuthActions from "@/composables/auth/useAuthActions.js";

export const repositoryClientModule = axios;

export const repositoryClient = repositoryClientModule.create({
	withCredentials: true,
	withXSRFToken: true, // OPCJA WYMAGANA OD WERSJI 1.6.2
	headers: {
		"X-Requested-With": "XMLHttpRequest",
	},
});

repositoryClient.interceptors.request.use(
	(config) => {
		useLoadingStore().requestStart(config.url);

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

repositoryClient.interceptors.response.use(
	(response) => {
		useLoadingStore().requestStop(response.config.url);

		return response;
	},
	(error) => {
		if (axios.isCancel(error)) {
			return Promise.reject(error);
		}

		useLoadingStore().requestStop(error.config.url);

		if (typeof error.response === "undefined") {
			return Promise.reject(error);
		}

		const { addErrorAlert } = useAlertAdd();

		if (400 === error.response.status) {
			let message = $i18n.global.t("request.error.400");
			if ("data" in error.response && "message" in error.response.data) message = error.response.data.message;
			addErrorAlert(message);
		}

		if (401 === error.response.status) {
			const { clearUser } = useAuthActions();
			clearUser();
		}

		if (403 === error.response.status) {
			addErrorAlert($i18n.global.t("request.error.403"));
		}

		if (503 === error.response.status) {
			addErrorAlert($i18n.global.t("request.error.503"));

			setTimeout(function () {
				window.location.reload();
			}, 3000);
		}

		if (500 === error.response.status) {
			addErrorAlert($i18n.global.t("request.error.500"));
		}

		return Promise.reject(error);
	}
);

export default { repositoryClientModule, repositoryClient };
