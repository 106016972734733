const filesCore = import.meta.glob("./*/*.json", { eager: true });
const filesApp = import.meta.glob("./../../lang/*/*.json", { eager: true });
const files = { ...filesCore, ...filesApp };
const messages = {};

for (const path in files) {
	const pathSplit = path.split("/");

	const lang = pathSplit[pathSplit.length - 2];
	const moduleName = pathSplit[pathSplit.length - 1].split(".")[0];
	if (!messages[lang]) {
		messages[lang] = {};
	}
	messages[lang][moduleName] = files[path][moduleName];
}

export default messages;
