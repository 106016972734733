import { defineStore } from "pinia";
import AuthRepository from "@/repositories/AuthRepository";

export const useAuthStore = defineStore("auth", {
	state: () => ({
		user: null,
		permissions: [],
	}),

	getters: {
		getUser(state) {
			return state.user;
		},
		getUserCurrencyObject(state) {
			if (!state.user) return null;

			return state.user.current_currency ?? null;
		},
		getUserCurrency() {
			if (!this.getUserCurrencyObject) return null;

			return this.getUserCurrencyObject.code;
		},
		getUserRoleId(state) {
			if (!state.user) return false;

			return state.user.role_id;
		},
		getUserRoleName(state) {
			if (!state.user) return false;

			return state.user.role.name;
		},
		getIsAuthenticated(state) {
			return state.user !== null;
		},
		getUserPermissions(state) {
			return state.permissions;
		},
	},

	actions: {
		check() {
			return new Promise((resolve, reject) => {
				AuthRepository.check()
					.then((result) => {
						this.updateUser(result.data.data);
						resolve(result);
					})
					.catch((result) => {
						this.clearUser();
						reject(result);
					});
			});
		},

		login(credentials) {
			return new Promise((resolve, reject) => {
				AuthRepository.login(credentials)
					.then((result) => {
						this.updateUser(result.data.data);
						resolve(result);
					})
					.catch((result) => {
						this.clearUser();
						reject(result);
					});
			});
		},

		logout() {
			return new Promise((resolve, reject) => {
				AuthRepository.logout()
					.then((result) => {
						this.clearUser();

						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},

		passwordForgot(data) {
			return new Promise((resolve, reject) => {
				AuthRepository.passwordForgot(data)
					.then((result) => {
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},

		passwordResetCheck(token) {
			return new Promise((resolve, reject) => {
				AuthRepository.passwordResetCheck(token)
					.then((result) => {
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},

		passwordReset(data) {
			return new Promise((resolve, reject) => {
				AuthRepository.passwordReset(data)
					.then((result) => {
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},

		verification(data) {
			return new Promise((resolve, reject) => {
				AuthRepository.verification(data)
					.then((result) => {
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},

		verification2fa(data) {
			return new Promise((resolve, reject) => {
				AuthRepository.verification2fa(data)
					.then((result) => {
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},

		update(data) {
			return new Promise((resolve, reject) => {
				AuthRepository.update(data)
					.then((result) => {
						this.updateUser(result.data.data);
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},

		updateUser(user) {
			this.user = user;
			this.savePermissions();
		},
		clearUser() {
			this.$reset();
			this.destroyPermissions();
		},
		deleteAccount() {
			return new Promise((resolve, reject) => {
				AuthRepository.deleteAccount()
					.then((result) => {
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
		savePermissions() {
			this.permissions = this.user.permissions;
		},
		destroyPermissions() {
			this.permissions = [];
		},
	},
});
