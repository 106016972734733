export const locales = [
	{
		id: "pl",
		slug: "pl",
		default: true,
		name: {
			pl: "Polski",
			en: "Polish",
		},
		dateTimeFormat: {
			date: "yyyy-MM-dd",
			time: "HH:mm",
			dateTime: "yyyy-MM-dd HH:mm",
		},
	},
	{
		id: "en",
		slug: "en",
		default: false,
		name: {
			pl: "Angielski",
			en: "English",
		},
		dateTimeFormat: {
			date: "MM/dd/yyyy",
			time: "hh:mm a",
			dateTime: "MM/dd/yyyy hh:mm a",
		},
	},
];
