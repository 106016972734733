import env from "@/env";
import useRepository from "@@/composables/useRepository.js";

const resource = `${env.API_URL}`;

export const csrfToken = () => {
	return useRepository().getRaw(`${resource}/csrf-cookie`);
};

export default { csrfToken };
