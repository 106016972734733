import { computed } from "vue";
import { useLocaleStore } from "@@/stores/useLocaleStore";

export default () => {
	const currentRoute = computed(() => {
		return $router.currentRoute.value;
	});

	const push = (to) => {
		if (typeof to == "object") {
			const toDefault = {
				params: {
					locale: useLocaleStore().getLocale,
				},
			};
			to = { ...toDefault, ...to };
		}

		return $router.push(to);
	};

	const go = (delta) => {
		$router.go(delta);
	};

	const getQuery = (key = null, defaultValue = null) => {
		if (!key) return currentRoute.value.query;

		if (undefined === currentRoute.value.query[key]) return defaultValue;

		return currentRoute.value.query[key];
	};

	const getParam = (key = null, defaultValue = null) => {
		if (!key) return currentRoute.value.params;

		if (undefined === currentRoute.value.params[key]) return defaultValue;

		return currentRoute.value.params[key];
	};

	const changeQuery = (query = {}, exclude = [], force = false) => {
		let newQuery = { ...currentRoute.value.query, ...query };

		exclude.forEach((toExclude) => {
			delete newQuery[toExclude];
		});

		return push({ name: currentRoute.value.name, query: newQuery, force: force })
			.then(() => {})
			.catch(() => {});
	};

	const currentRouteHasParent = (name) => {
		if (!name) return true;

		if (!("matched" in currentRoute.value)) return false;

		if (!currentRoute.value.matched.length) return false;

		return currentRoute.value.matched.reduce((result, element) => {
			if (element.name === name) result = true;

			return result;
		}, false);
	};

	return {
		router: $router,
		currentRoute,
		push,
		go,
		getQuery,
		getParam,
		changeQuery,
		currentRouteHasParent,
	};
};
