export const maps = {
	google: {
		apiKey: "AIzaSyCliiSGPLBojshZtdBEw0cl6GLz6q2MQhw",
		center: { lat: 52.04, lng: 19.28 },
		styles: [
			{ featureType: "administrative", elementType: "labels", stylers: [{ visibility: "on" }, { gamma: "1.82" }] },
			{
				featureType: "administrative",
				elementType: "labels.text.fill",
				stylers: [{ visibility: "on" }, { gamma: "1.96" }, { lightness: "-9" }],
			},
			{ featureType: "administrative", elementType: "labels.text.stroke", stylers: [{ visibility: "off" }] },
			{ featureType: "administrative.province", elementType: "labels", stylers: [{ color: "#d3ae00" }] },
			{ featureType: "administrative.province", elementType: "labels.text", stylers: [{ color: "#c3b884" }] },
			{ featureType: "administrative.locality", elementType: "labels.text", stylers: [{ color: "#515151" }] },
			{ featureType: "administrative.neighborhood", elementType: "labels", stylers: [{ color: "#afafaf" }] },
			{
				featureType: "landscape",
				elementType: "all",
				stylers: [{ visibility: "on" }, { lightness: "25" }, { gamma: "1.00" }, { saturation: "-100" }],
			},
			{ featureType: "poi.business", elementType: "all", stylers: [{ visibility: "off" }] },
			{ featureType: "poi.park", elementType: "all", stylers: [{ visibility: "off" }] },
			{ featureType: "road", elementType: "geometry.stroke", stylers: [{ visibility: "off" }] },
			{ featureType: "road", elementType: "labels.icon", stylers: [{ visibility: "off" }] },
			{ featureType: "road.highway", elementType: "geometry", stylers: [{ saturation: "-43" }, { visibility: "on" }, { color: "#cdb79b" }] },
			{ featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ visibility: "off" }] },
			{ featureType: "road.highway", elementType: "labels", stylers: [{ saturation: "-30" }, { lightness: "26" }] },
			{ featureType: "road.highway.controlled_access", elementType: "labels", stylers: [{ visibility: "on" }] },
			{ featureType: "road.arterial", elementType: "all", stylers: [{ visibility: "on" }, { saturation: "-100" }, { lightness: "30" }] },
			{ featureType: "road.arterial", elementType: "geometry", stylers: [{ color: "#e4cdb0" }] },
			{ featureType: "road.arterial", elementType: "labels.text", stylers: [{ visibility: "on" }] },
			{ featureType: "road.local", elementType: "all", stylers: [{ saturation: "-100" }, { lightness: "40" }, { visibility: "off" }] },
			{ featureType: "road.local", elementType: "geometry", stylers: [{ visibility: "on" }, { color: "#ffffff" }] },
			{ featureType: "road.local", elementType: "labels.text", stylers: [{ visibility: "on" }] },
			{ featureType: "transit.station.airport", elementType: "geometry.fill", stylers: [{ visibility: "on" }, { gamma: "0.80" }] },
			{ featureType: "water", elementType: "all", stylers: [{ visibility: "off" }, { color: "#bfbfbf" }] },
			{ featureType: "water", elementType: "geometry.fill", stylers: [{ visibility: "on" }] },
			{ featureType: "water", elementType: "geometry.stroke", stylers: [{ color: "#454545" }, { visibility: "off" }] },
			{ featureType: "water", elementType: "labels", stylers: [{ visibility: "on" }, { color: "#5e5e5e" }, { weight: "0.01" }] },
		],
	},
};
