import useAlertState from "@@/composables/alert/useAlertState.js";
import { AlertTypeEnum } from "@@/enums/AlertTypeEnum.js";

export default () => {
	const { pushAlert } = useAlertState();

	const addAlert = (alert, type = null) => {
		return pushAlert(alert, type);
	};

	const addInfoAlert = (alert) => {
		addAlert(alert, AlertTypeEnum.INFO);
	};

	const addSuccessAlert = (alert) => {
		addAlert(alert, AlertTypeEnum.SUCCESS);
	};

	const addWarningAlert = (alert) => {
		addAlert(alert, AlertTypeEnum.WARNING);
	};

	const addErrorAlert = (alert) => {
		addAlert(alert, AlertTypeEnum.ERROR);
	};

	return {
		addAlert,
		addInfoAlert,
		addSuccessAlert,
		addWarningAlert,
		addErrorAlert,
	};
};
