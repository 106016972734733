import { PermissionEnum } from "@/enums/PermissionEnum.js";
const sharedRoutes = [
	{
		name: "dashboard",
		path: "dashboard",
		component: () => import("@/views/pages/dashboard/Dashboard.vue"),
		children: [
			{
				name: "dashboard.analytics.locationPreview",
				path: "analytics/maps/location-preview/:id",
				component: () => import("@/views/Blank.vue"),
				meta: {
					permissions: [PermissionEnum.SHARED__ANALYTICS_MAPS_CONTRACTOR_ADDRESS__READ],
					aside: () => import("@/views/pages/analytics/maps/preview/AnalyticsMapsMapLocationPreview.vue"),
					breadcrumbs: {
						hidden: true,
					},
				},
			},
		],
	},
	{
		name: "profile",
		path: "profile",
		component: () => import("@/views/pages/profile/Profile.vue"),
	},
	{
		name: "distributors.index",
		path: "distributors",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.SHARED__DISTRIBUTORS__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "distributors.list",
		},
		children: [
			{
				name: "distributors.list",
				path: "list",
				component: () => import("@/views/pages/distributors/list/DistributorsListing.vue"),
				children: [
					{
						name: "distributors.add",
						path: "add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/distributors/DistributorsAdd.vue"),
							permissions: [PermissionEnum.SHARED__DISTRIBUTORS__CRUD],
						},
					},
					{
						name: "distributors.edit",
						path: "edit/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/distributors/DistributorsEdit.vue"),
							permissions: [PermissionEnum.SHARED__DISTRIBUTORS__CRUD],
						},
					},
				],
			},
		],
	},
	{
		name: "contractors.index",
		path: "contractors",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.SHARED__CONTRACTORS__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "contractors.list",
		},
		children: [
			{
				name: "contractors.list",
				path: "list",
				component: () => import("@/views/pages/contractors/list/ContractorsListing.vue"),
				children: [
					{
						name: "contractors.add",
						path: "add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/contractors/ContractorsAdd.vue"),
							permissions: [PermissionEnum.SHARED__CONTRACTORS__CREATE],
						},
					},
					{
						name: "contractors.edit",
						path: "edit/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/contractors/ContractorsEdit.vue"),
							permissions: [PermissionEnum.SHARED__CONTRACTORS__UPDATE],
						},
					},
				],
			},
		],
	},
	{
		name: "contractorsAddresses.index",
		path: "contractors-addresses",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.SHARED__CONTRACTORS__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "contractorsAddresses.list",
		},
		children: [
			{
				name: "contractorsAddresses.list",
				path: "list",
				component: () => import("@/views/pages/contractors_addresses/list/ContractorsAddressesListing.vue"),
				children: [
					{
						name: "contractorsAddresses.add",
						path: "add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/contractors_addresses/ContractorsAddressesAdd.vue"),
							permissions: [PermissionEnum.SHARED__CONTRACTORS__CREATE],
						},
					},
					{
						name: "contractorsAddresses.edit",
						path: "edit/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/contractors_addresses/ContractorsAddressesEdit.vue"),
							permissions: [PermissionEnum.SHARED__CONTRACTORS__UPDATE],
						},
					},
				],
			},
		],
	},
	{
		name: "displayTypes.index",
		path: "display-types",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.SHARED__DISPLAYS_TYPE__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "displayTypes.list",
		},
		children: [
			{
				name: "displayTypes.list",
				path: "list",
				component: () => import("@/views/pages/display_types/list/DisplayTypesListing.vue"),
				children: [
					{
						name: "displayTypes.add",
						path: "add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/display_types/DisplayTypesAdd.vue"),
							permissions: [PermissionEnum.ADMIN__DISPLAYS_TYPE__CRUD],
						},
					},
					{
						name: "displayTypes.edit",
						path: "edit/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/display_types/DisplayTypesEdit.vue"),
							permissions: [PermissionEnum.ADMIN__DISPLAYS_TYPE__CRUD],
						},
					},
					{
						name: "displayTypes.preview",
						path: "preview/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/display_types/DisplayTypesPreview.vue"),
							permissions: [PermissionEnum.SHARED__DISPLAYS_TYPE__READ],
						},
					},
				],
			},
		],
	},
	{
		name: "orders.index",
		path: "orders",
		component: () => import("@/views/Blank.vue"),
		meta: {
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "orders.list",
		},
		children: [
			{
				name: "orders.list",
				path: "list",
				component: () => import("@/views/pages/orders/list/OrdersListing.vue"),
				meta: {
					permissions: [PermissionEnum.SHARED__ORDERS__READ],
				},
				children: [
					{
						name: "orders.preview",
						path: "preview/:id",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/orders/edit/OrdersPreview.vue"),
							permissions: [PermissionEnum.SHARED__ORDERS__READ],
						},
					},
				],
			},
			{
				name: "orders.cart",
				path: "cart",
				component: () => import("@/views/pages/orders/cart/list/OrdersCartListing.vue"),
				meta: {
					permissions: [PermissionEnum.SHARED__ORDERS__CREATE],
				},
				children: [
					{
						name: "orders.cart.displayTypes.preview",
						path: "preview/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/display_types/DisplayTypesPreview.vue"),
							permissions: [PermissionEnum.SHARED__DISPLAYS_TYPE__READ],
						},
					},
				],
			},
			{
				name: "orders.checkout",
				path: "checkout",
				component: () => import("@/views/pages/orders/checkout/OrdersCheckout.vue"),
				meta: {
					permissions: [PermissionEnum.SHARED__ORDERS__CREATE],
				},
				children: [
					{
						name: "orders.checkout.contractors.add",
						path: "contractors-add",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/orders/checkout/contractor/OrdersCheckoutContractorAdd.vue"),
							permissions: [PermissionEnum.SHARED__CONTRACTORS__CREATE],
						},
					},
					{
						name: "orders.checkout.contractorsAddresses.add",
						path: "contractors-addresses-add/:contractorId?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/orders/checkout/contractor_address/OrdersCheckoutContractorAddressAdd.vue"),
							permissions: [PermissionEnum.SHARED__CONTRACTORS__CREATE],
						},
					},
				],
			},
		],
	},
	{
		name: "analytics.index",
		path: "analytics",
		component: () => import("@/views/pages/analytics/maps/AnalyticsMaps.vue"),
		meta: {
			permissions: [PermissionEnum.SHARED__ANALYTICS_MAPS_CONTRACTOR_ADDRESS__READ],
		},
		children: [
			{
				name: "analytics.locationPreview",
				path: "location-preview/:id",
				component: () => import("@/views/Blank.vue"),
				meta: {
					aside: () => import("@/views/pages/analytics/maps/preview/AnalyticsMapsMapLocationPreview.vue"),
				},
			},
		],
	},
	{
		name: "displays.index",
		path: "displays",
		component: () => import("@/views/Blank.vue"),
		meta: {
			permissions: [PermissionEnum.SHARED__DISPLAYS__READ],
			breadcrumbs: {
				hidden: true,
			},
		},
		redirect: {
			name: "displays.list",
		},
		children: [
			{
				name: "displays.list",
				path: "list",
				component: () => import("@/views/pages/displays/list/DisplaysListing.vue"),
				children: [
					{
						name: "displays.addressLogs",
						path: "address-logs/:id",
						component: () => import("@/views/Blank.vue"),
						meta: {
							aside: () => import("@/views/pages/displays/address_logs/DisplaysAddressLogs.vue"),
							permissions: [PermissionEnum.SHARED__DISPLAYS_ADDRESS_LOGS__READ],
						},
					},
				],
			},
		],
	},
];

export default sharedRoutes;
