import { useLocaleStore } from "@@/stores/useLocaleStore";

export const LocaleExistsMiddleware = async ({ to, next }) => {
	if (to.params.locale && !useLocaleStore().getExists(to.params.locale)) {
		return () => {
			return { name: to.name, params: { locale: useLocaleStore().getDefaultLocale().slug } };
		};
	}
	return await next();
};

export default LocaleExistsMiddleware;
