import { createI18n as _createI18n } from "vue-i18n";
import { useLocaleStore } from "@@/stores/useLocaleStore";
import messages from "@@/lang";

export const createI18n = () => {
	return _createI18n({
		legacy: false,
		locale: useLocaleStore().getLocale,
		fallbackLocale: useLocaleStore().getDefaultLocale.slug,
		messages: messages,
		silentTranslationWarn: false,
		silentFallbackWarn: false,
	});
};

export default createI18n;
