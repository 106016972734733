import { computed } from "vue";
import useAuthActions from "@/composables/auth/useAuthActions.js";

export default () => {
	const { authStore } = useAuthActions();

	const isAuthenticated = computed(() => {
		return authStore.getIsAuthenticated;
	});

	const check = async () => {
		try {
			return await authStore.check();
		} catch (response) {
			throw new Error(response);
		}
	};

	return {
		isAuthenticated,
		check,
	};
};
