import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
import useConfig from "@@/composables/useConfig";

export const useLocaleStore = defineStore("locale", {
	state: () => ({
		localeSelected: useLocalStorage("pinia/locale/selected", ""),
		localeCurrent: useLocalStorage("pinia/locale/current", ""),
		to: null,
	}),

	getters: {
		getLocaleSelected(state) {
			return state.localeSelected;
		},
		getIsLocaleSelected(state) {
			return !!state.localeSelected;
		},
		getLocales(state) {
			return useConfig().locales;
		},
		getDefaultLocale(state) {
			return this.getLocales.reduce((result, element) => (element.default ? element : result), {});
		},
		getLocaleBySlug(state) {
			return (slug) => {
				return this.getLocales.reduce((result, element) => (element.slug === slug ? element : result), {});
			};
		},
		getCurrentLocale(state) {
			return state.localeCurrent;
		},
		getExists(state) {
			return (localeSlug) => {
				return !!this.getLocales.map((locale) => locale.slug).some((locale) => locale === localeSlug);
			};
		},
		getHasLocale(state) {
			return null !== this.getCurrentLocale && this.getExists(this.getCurrentLocale);
		},

		getLocale(state) {
			if (this.getHasLocale) return this.getCurrentLocale;
			return this.getDefaultLocale.slug;
		},
		getLocaleObject(state) {
			return this.getLocaleBySlug(this.getLocale);
		},
		getTo(state) {
			return state.to;
		},
	},

	actions: {
		setLocaleSelected(locale) {
			this.localeSelected = locale;
		},
		setLocale(localeSlug) {
			if (typeof localeSlug === "object") localeSlug = localeSlug.slug;

			if (!this.getExists(localeSlug)) return false;

			this.localeCurrent = localeSlug;
		},
		destroyLocale() {
			this.localeCurrent = "";
		},
		setTo(to) {
			this.to = to;
		},
	},
});
