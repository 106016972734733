import useCommentNode from "@@/composables/useCommentNode";
import useAuthPermissions from "@/composables/auth/useAuthPermissions.js";

export default {
	can: {
		mounted: (el, binding, vnode, prevVnode) => {
			const { hasPermissions, hasAnyPermissions, hasNotPermissions } = useAuthPermissions();

			if (binding.modifiers.any) {
				if (!!hasAnyPermissions(binding.value)) return true;
			} else if (binding.modifiers.not) {
				if (!!hasNotPermissions(binding.value)) return true;
			} else if (!!hasPermissions(binding.value)) return true;

			if (!!binding.modifiers.disabled) {
				el.style.display = "none";
				return true;
			}

			const { commentNode } = useCommentNode();

			commentNode(el, vnode, "v-can");

			return true;
		},
	},
};
