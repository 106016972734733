const filesCore = import.meta.glob("./*.js", { eager: true });
const filesApp = import.meta.glob("./../../directives/*.js", { eager: true });
const files = { ...filesCore, ...filesApp };
let directives = {};

for (const file in files) {
	directives = { ...directives, ...files[file].default };
}

export default directives;
