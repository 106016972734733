import { useLocaleStore } from "@@/stores/useLocaleStore";
import { RouteEnum } from "@@/enums/RouteEnum.js";
import useRouter from "@@/composables/route/useRouter";

export const LocaleSelectedMiddleware = ({ to, next }) => {
	const localeStore = useLocaleStore();
	const { push } = useRouter();

	if (!localeStore.getIsLocaleSelected && to.name !== RouteEnum.LOCALE_CHOOSE) {
		localeStore.setTo(to);
		return push({ name: RouteEnum.LOCALE_CHOOSE });
	}

	if (localeStore.getIsLocaleSelected && to.name !== RouteEnum.LOCALE_CHOOSE) {
		const localeSelected = localeStore.getLocaleSelected;
		if (!localeStore.getExists(localeSelected)) {
			localeStore.setLocaleSelected(null);
			return push({ name: RouteEnum.LOCALE_CHOOSE });
		}

		const thisLocale = to.params.locale;
		if (thisLocale !== localeSelected) {
			to.params.locale = localeSelected;
			return push({
				...to,
				params: {
					locale: localeSelected,
				},
			});
		}
	}

	return next();
};

export default LocaleSelectedMiddleware;
