import config from "@@/config";
import { defineStore } from "pinia";
import useAuthUser from "@/composables/auth/useAuthUser.js";

export const useCurrencyStore = defineStore("currency", {
	state: () => ({
		currencies: [],
		current: "",
		user: null,
	}),

	getters: {
		getCurrencies(state) {
			return state.currencies;
		},
		getDefault(state) {
			return this.getCurrencies.reduce((result, element) => (element.is_default || element.default ? element : result), {});
		},
		geByCode(state) {
			return (code) => {
				return this.getCurrencies.find((element) => element.code.toLowerCase() === code?.toLowerCase());
			};
		},
		getCurrent(state) {
			return state.current;
		},
		getExists(state) {
			return (code) => {
				return !!this.getCurrencies
					.map((currency) => currency.code)
					.some((currency) => "" + currency.toLowerCase() === "" + code.toLowerCase());
			};
		},
		getHasCurrency(state) {
			return null !== this.getCurrent && this.getExists(this.getCurrent);
		},

		getCurrency(state) {
			if (this.getHasCurrency && this.getCurrent) return this.getCurrent ?? "";
			return this.getDefault.code ?? "";
		},
		getCurrencyObject(state) {
			return this.geByCode(this.getCurrency);
		},
		getCurrencyName(state) {
			const currencyObject = this.getCurrencyObject;

			if (typeof currencyObject !== "object") return "";

			if ("locale_translation" in currencyObject) return currencyObject.locale_translation.name;

			return currencyObject.name;
		},
	},

	actions: {
		init(force = false) {
			const { userCurrency } = useAuthUser();

			this.setCurrencies();

			this.setCurrent(userCurrency.value);
		},
		setCurrencies(currencies) {
			if (!currencies || !Array.isArray(currencies)) this.currencies = config.currencies;
			else this.currencies = currencies;
		},
		setCurrent(code = null) {
			if (!code) code = this.getDefault.code;

			if (typeof code === "object") code = code.code;

			if (!this.getExists(code)) return false;

			this.current = code;

			return true;
		},
		destroy() {
			this.$reset();
		},
	},
});
