import { useLocaleStore } from "@@/stores/useLocaleStore";
import { RouteEnum } from "@@/enums/RouteEnum.js";
import useAuthStatus from "@/composables/auth/useAuthStatus.js";

export const AuthPassedMiddleware = async ({ to, next }) => {
	const { check, isAuthenticated } = useAuthStatus();

	if (isAuthenticated.value) return next();

	return await check()
		.then(async (response) => {
			return await next();
		})
		.catch((response) => {
			const locale = to.params.locale ? to.params.locale : useLocaleStore().getLocale;
			return { name: RouteEnum.LOGIN, params: { locale: locale } };
		});
};

export default AuthPassedMiddleware;
