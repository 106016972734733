import { polyfill } from "es6-promise";
import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { createPinia } from "pinia";
import { createVuetify } from "@@/vuetify";
import { createI18n } from "@@/i18n";
import { routes, beforeEach, afterEach } from "@@/routes";
import "@@/yup/yupCustomRules";
import directives from "@@/directives";
import App from "@/App.vue";

polyfill();

export const app = createApp(App);

const router = createRouter({
	history: createWebHistory(),
	routes: routes,
	scrollBehavior(to, from, savedPosition) {
		if ("false" === to.query.scrollBehavior || "0" === to.query.scrollBehavior) return savedPosition;

		if (to.hash) return { el: to.hash };
		return { top: 0 };
	},
});
app.use(router);
globalThis.$router = router;
router.beforeEach(async (to, from) => {
	const beforeEachResult = await beforeEach(to, from);
	if (typeof beforeEachResult === "object" || beforeEachResult === false) {
		return beforeEachResult;
	}
});
router.afterEach(async (to, from) => {
	return await afterEach(to, from);
});

const pinia = createPinia();
globalThis.$pinia = pinia;
app.use(pinia);

const vuetify = createVuetify();
app.use(vuetify);

const i18n = createI18n();
globalThis.$i18n = i18n;
app.use(i18n);

Object.keys(directives).forEach((name) => {
	app.directive(name, directives[name]);
});

export default { app };
