import { RouteEnum } from "@@/enums/RouteEnum.js";
import { useLocaleStore } from "@@/stores/useLocaleStore";
import useAuthPermissions from "@/composables/auth/useAuthPermissions.js";

export const PermissionsMiddleware = async ({ to, from, next, permissions }) => {
	if (!permissions) {
		return await next();
	}
	if (typeof permissions == "string") permissions = [permissions];

	const { hasPermissions } = useAuthPermissions();

	if (permissions.length && !hasPermissions(permissions)) {
		return { name: RouteEnum.ERROR_403, params: { locale: useLocaleStore().getLocale } };
	}

	return await next();
};

export default PermissionsMiddleware;
